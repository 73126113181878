import React, { Component } from "react";
import { Link, navigate } from 'gatsby';
import Logo from "../../images/weighworks-logo.svg";
import TrustedOne from "../../images/trusted-one.svg";
import TrustedTwo from "../../images/trusted-two.svg";
import TrustedThree from "../../images/trusted-three.svg";
import TrustedFour from "../../images/trusted-four.svg";
import InputMask from 'react-text-mask'

const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
]
class SignUppage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      workemail: "",
      companyname: "",
      companyphone: "",
      dumps: "",
      notes: "",
      err: {},
      errMessage: "",
      isLoading: false
    };
  }
  handleChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  validate() {
    let errObj = {}
    const { firstname, lastname, companyname, workemail, companyphone, dumps } = this.state
    if(firstname === "") {
      errObj.firstname = "This field is required"
    }
    if(lastname === "") {
      errObj.lastname = "This field is required"
    }
    if(companyname === "") {
      errObj.companyname = "This field is required"
    }
    if(workemail === "") {
      errObj.workemail = "This field is required"
    }
    if (workemail !== "") {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(workemail) === false) { // eslint-disable-line
        errObj.workemail = "Invalid email format"
      }
    }
    if(companyphone === "") {
      errObj.companyphone = "This field is required"
    }
    const phonelength = companyphone.replace(/[^0-9]/g,"").length
    if(companyphone !== "" && phonelength !== 10) {
      errObj.companyphone = "Please enter exactly 10 digit number"
    }
    if(dumps === "") {
      errObj.size = "This field is required"
    }
    this.setState({ err: errObj },() => {
      this.forceUpdate()
    })
  }
  onSubmit= async () => {
    const HOSTNAME = process.env.REACT_APP_API_URL
    this.setState({ errMessage: "", isLoading: true })
    await this.validate()
    const { err } = this.state
    if(Object.keys(err).length === 0) {
      const { firstname, lastname, companyname, workemail, companyphone, dumps, notes } = this.state
      const data = {
        firstname, lastname, companyname, companyphone: companyphone.replace(/[^0-9]/g,""), workemail, dumps, notes
      }
      fetch(`${HOSTNAME}/huspot-request`,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }).then((res) =>  {
        return res.json()
      }).then((data) => {
        this.setState({ isLoading: false })
        if(data && data.status) {
          navigate("/signup-success")
        } else {
          this.setState({ errMessage: "Error in processing the request."})
        }
      }).catch((err) => {
        this.setState({ isLoading: false })
        this.setState({ errMessage: "Error in processing the request."})
        throw err
      });
    }
  }
  render() {
    const { err, errMessage, isLoading } = this.state
    return (
      <div>
        <section className="signup-section">
          <div className="container">
            <div className="col-md-12">
              <div className="signup-listing">
                <Link to={'/'}><img src={Logo} alt="" className="logoimg" /></Link>
                <h4>Request a personalized demo of WeighWorks</h4>
                <p>Fill out the form below & one of our experts will get in touch with you.</p>
                <ul>
                  <li>
                    <h5>Eliminate fraud </h5>
                    <p>Monitor your entire operation with the click of a button.</p>
                  </li>
                  <li>
                    <h5>Automated invoices</h5>
                    <p>Real time invoice generation, saving you time and money and making sure nothing is missed. </p>
                  </li>
                  <li>
                    <h5>Driver Verification </h5>
                    <p>Driver identification integration that automatically scans the drivers licenses of everybody who comes to your facility.</p>
                  </li>
                </ul>
                <div className="trusted-wrapper">
                  <h4>Trusted By</h4>
                  <div className="trusted">
                  <div><img src={TrustedOne} alt="" /></div>
                  <div><img src={TrustedTwo} alt="" /></div>
                  <div><img src={TrustedThree} alt="" /></div>
                  <div><img src={TrustedFour} alt="" /></div>
                </div>
                </div>
              </div>
              <div className="form-bg">
                <div className="form-border">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="labels" htmlFor="firstname">First Name</label>
                      <input 
                        id="firstname"
                        name="firstname"
                        type="text" 
                        className="inp-field" 
                        placeholder="Enter your first name"
                        value={this.state.firstname}
                        onChange={(event) => this.handleChange(event)} 
                      />
                      <p className="text-danger">{err && err.firstname ? err.firstname : ""}</p>
                    </div>
                    <div className="col-md-6">
                      <label className="labels" htmlFor="lastname">Last Name</label>
                      <input 
                        id="lastname"
                        name="lastname"
                        type="text" 
                        className="inp-field" 
                        placeholder="Enter your last name"
                        value={this.state.lastname}
                        onChange={(event) => this.handleChange(event)} 
                      />
                      <p className="text-danger">{err && err.lastname ? err.lastname : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="labels" htmlFor="companyname">Company Name</label>
                      <input 
                        id="companyname"
                        name="companyname"
                        type="text" 
                        className="inp-field" 
                        placeholder="What’s your companies name?"
                        value={this.state.companyname}
                        onChange={(event) => this.handleChange(event)}
                      />
                      <p className="text-danger">{err && err.companyname ? err.companyname : ""}</p>
                    </div>
                    <div className="col-md-6">
                      <label className="labels" htmlFor="workemail">Work Email</label>
                      <input 
                        id="workemail"
                        name="workemail"
                        type="text" 
                        className="inp-field" 
                        placeholder="Enter your work email address?"
                        value={this.state.workemail}
                        onChange={(event) => this.handleChange(event)} 
                      />
                      <p className="text-danger">{err && err.workemail ? err.workemail : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="labels" htmlFor="companyphone">Phone Number</label>
                      <InputMask
                        type="text"
                        guide={false}
                        keepCharPositions={false}
                        className="inp-field"
                        name="companyphone"
                        mask={phoneNumberMask}
                        placeholder="Enter best number to reach you at?"
                        value={this.state.companyphone}
                        onChange={(event) => this.handleChange(event)}
                      />
                      <p className="text-danger">{err && err.companyphone ? err.companyphone : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="labels" htmlFor="dumps">How many dumps do you process a day?</label>
                      <select 
                        id="dumps"
                        name="dumps"
                        className="select-field"
                        value={this.state.dumps}
                        onChange={(event) => this.handleChange(event)}
                      >
                        <option value="1-10">1-10</option>
                        <option value="10-50">10-50</option>
                        <option value="100-500">100-500</option>
                        <option value="500+">500+</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="labels" htmlFor="notes">Anything else? (optional)</label>
                      <textarea 
                        id="notes"
                        name="notes"
                        className="textarea-field" 
                        placeholder="Let us know if there is anything else you would like for us to know about you cleanout..."
                        value={this.state.notes}
                        onChange={(event) => this.handleChange(event)}
                        ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                    <button 
                        type="submit" 
                        className="btn-demo"
                        disabled={isLoading}
                        onClick={this.onSubmit.bind(this)}
                      >
                        { isLoading ? "Please wait..." : "Request a demo" }
                      </button>
                      <p className="text-danger text-center">{errMessage}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default SignUppage;
