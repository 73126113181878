import React from "react"
import Layout from "../components/layout"
import SignUp from "../components/marketing/signup"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const SignUpPage = () => (
  <Layout>
    <SEO title="SignUp" />
    <Helmet>
      <meta name="description" content="The first cloud based system to track all material going in and out of your facility in real time." />
      <meta property="og:description" content="The first cloud based system to track all material going in and out of your facility in real time." />
      <meta name="twitter:description" content="The first cloud based system to track all material going in and out of your facility in real time." />
    </Helmet>
    <SignUp/>
  </Layout>
)

export default SignUpPage
